import React, { useState } from "react"

import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import SafeAreaWrapper from "../components/SafeAreaWrapper"

import Grid from "../components/Grid"
import { Button } from "../components/Button"

// import Hero from "../components/Hero"
import SmallStage from "../components/SmallStage"
import Title from "../components/Title"

import { BlueBanner } from "../components/Banner"

import { ArrowheadFatDown, Faq, Mail } from "../components/Icon"

const AccordionItem = ({ faq, onToggle, active, index = 0 }) => {
	const { question, answer } = faq
	return (
		<li className="py-30 border-solid border-iron border-t last:border-b">
			<div
				className="flex flex-row justify-between group hover:cursor-pointer"
				onClick={onToggle}
				onKeyDown={onToggle}
				role="button"
				tabIndex={index}>
				<p
					className={`font-semibold transform transition-colors duration-250  ${
						active ? "text-tealblue" : "text-jetblack group-hover:text-tealblue"
					}`}>
					{question}
				</p>
				<span className="flex flex-col justify-center">
					<ArrowheadFatDown
						className={`mx-30 fill-current group-hover:text-tealblue transform transition-transform duration-250 ${
							active ? "rotate-180 text-tealblue" : "rotate-0 text-pictonblue"
						}`}
					/>
				</span>
			</div>
			<p
				className={`transform transition-all duration-250 overflow-hidden xl:mr-[115px] ${
					active ? "h-auto mt-20 opacity-100" : "h-0 mt-0 opacity-0"
				}`}>
				{answer}
			</p>
		</li>
	)
}

const Accordion = ({ faqs }) => {
	const [clicked, setClicked] = useState("0")

	const handleToggle = index => {
		if (clicked === index) {
			return setClicked("0")
		}
		setClicked(index)
	}

	return (
		<ul>
			{faqs.map((faq, index) => (
				<AccordionItem
					faq={faq}
					onToggle={() => handleToggle(index)}
					active={clicked === index}
					index={index}
				/>
			))}
		</ul>
	)
}

const FaqPage = ({ data: { page, recentPressCoverage, backgroundImage, bannerImage, faqs } }) => {
	return (
		<Layout translucent={false}>
			<Seo
				title={page.seo.title}
				description={page.seo.description}
				keywords={page.seo.keywords}
			/>
			<SmallStage className="pt-[60px] md:pt-[80px]">
				<Title title="Frequently Asked Questions" small={true} tint={false} />
			</SmallStage>
			<div className="py-spacer-xl">
				<SafeAreaWrapper>
					<Grid className="gap-y-60">
						<div className="col-span-12 lg:col-span-7 xl:col-span-8">
							<Accordion
								faqs={faqs.edges.map(edge => ({
									question: edge.node.question,
									answer: edge.node.answer,
								}))}
							/>
						</div>
						<div className="col-span-12 lg:col-span-5 xl:col-span-4">
							<aside className="bg-porcelain px-30 py-60 flex flex-col items-center rounded-medium">
								<Faq className="text-pictonblue" />
								<h2 className="display2 text-center my-30">
									More questions? Reach out to us!
								</h2>
								<a href="mailto:sales@hanseaticsoft.com">
									<Button primary={true}>
										<Mail />
										<span className="ml-8">Contact us</span>
									</Button>
								</a>
							</aside>
						</div>
					</Grid>
					<BlueBanner
						className="mt-spacer-xl"
						title={page.banner.title}
						subtitle={page.banner.pretext}
						image={
							page.banner.screenshot
								? getImage(page.banner.screenshot.localFile)
								: null
						}
						callToAction="Schedule a demo"
						callToActionUrl="/demo"
					/>
				</SafeAreaWrapper>
			</div>
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		page: strapiFaqPage {
			seo {
				title
				description
				keywords
			}
			banner {
				pretext
				title
				screenshot {
					alternativeText
					localFile {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 760)
						}
					}
				}
			}
		}
		backgroundImage: file(relativePath: { eq: "gradient.png" }) {
			childImageSharp {
				gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
			}
		}

		faqs: allStrapiFaq {
			edges {
				node {
					question
					answer
				}
			}
		}
	}
`

export default FaqPage
